import ReactDOM from 'react-dom'
import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, useHelper, gridHelper } from '@react-three/drei'
import { MeshBasicMaterial, MeshStandardMaterial, BoxGeometry, PointLightHelper, MathUtils } from 'three'
import './App.css'

const Torus = (props) => {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()

  useFrame((state, delta) => (ref.current.rotation.x += 0.01))
  useFrame((state, delta) => (ref.current.rotation.y += 0.005))
  useFrame((state, delta) => (ref.current.rotation.z += 0.01))
  
  return (
    <mesh
      ref={ref}>
      <torusGeometry args={[10, 3, 16, 100]} />
      <meshStandardMaterial color="blue" />
    </mesh>
  )
}

const Light = () => {
  const ref = useRef()
  // useHelper(ref, PointLightHelper, 1)

  return <pointLight ref={ref} position={[5, 5, 5]} position={[5, 5, 5]} />
}

const AddStar = (props) => {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  const [x, y, z] = Array(3).fill().map(() => MathUtils.randFloatSpread( 100 ) );
  console.log("star")

  return (
    <mesh
      ref={ref}
      position={[x, y, z]}>
      <sphereGeometry args={[0.25, 24, 24]} />
      <meshStandardMaterial color="white" />
      
    </mesh>
  )
}

const App = () => {

  return (
    <div id="wrapperItem">
      <Canvas camera={{ fov: 75, position: [0, 0, 30] }} onCreated={state => state.gl.setClearColor("black")}>
        <OrbitControls />
        <ambientLight />
        {/* <gridHelper args={[200, 50]} /> */}
        <Light />
        <Torus />
        {
          Array(200).fill().map((i) => 
            <AddStar />
          )
        }
        <AddStar />
      </Canvas>
    </div>
  );
}

export default App;
